<template>
  <div id="cloud-user-profile">

    <cloud-header :email="user ? user.email: null" :title="user ? (user.firstName + ' ' + user.lastName) : 'Χρήστης'"
                  subtitle="Εξερεύνηση του προφίλ του χρήστη"
      :stats="stats"/>

    <cloud-popular-listings-card v-if="user" :user-id="user.id"></cloud-popular-listings-card>
  </div>

</template>

<script>

import CloudHeader from "@components/cloud/CloudHeader";
import CloudPopularListingsCard from "@components/cloud/CloudPopularListingsCard";

export default{

  components: { CloudHeader, CloudPopularListingsCard},


  mounted(){
    this.fetchUserProfile();
  },
  data(){
    return{

      user: null,
      listings: null,
      stats: null
    }
  },

  methods:{

    fetchUserProfile(){
      this.$axios.get('/cloud/listings/user', {
        params:{
          userId: this.$route.params.userId
        }
      }).then(res=>{
        this.user = res.data.user;
        this.listings = res.data.listings;
        this.stats = res.data.stats;
      }).catch(e => {
        console.log(e);
      })
      }
  }




}
</script>
