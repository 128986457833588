<template>

  <div class="popular-listings-cards">
    <div class="row mt-4">
      <div class="col-12 col-md-6">

          <h4 class="text-primary">Δημοφιλή διατροφικά πλάνα</h4>
          <cloud-listing v-for="dp in dpListings" :key="dp.id" :item="dp"/>
        <div v-if="dpListings.length ===0">
          <p class="mt-3">
            Δεν βρέθηκαν διατροφικά πλάνα.
          </p>

        </div>
      </div>
      <div class="col-12 col-md-6">
        <h4 class="text-primary">Δημοφιλείς συνταγές</h4>
        <cloud-listing v-for="dp in recipeListings" :key="dp.id" :item="dp"/>
        <div v-if="recipeListings.length ===0">
          <p class="mt-3">
            Δεν βρέθηκαν τρόφιμα.
          </p>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CloudListing from "@components/cloud/CloudListing";

export default{
  components:{CloudListing},
  props:{
    userId:{
      type: String,
      default: null
    }

  },
  mounted(){
    this.fetchListings();
  },

  data(){
    return{
      dpListings: [],
      recipeListings: []
    }
  },

  methods:{

    fetchListings(){
      this.$axios.get(`/cloud/listings/popular-listings`, {params: {userId: this.userId}}).then( result => {

        this.dpListings = result.data.dp;
        this.recipeListings = result.data.recipes;
      }).catch(e=>{});
    }
  }
}
</script>
